import { Component, OnInit } from '@angular/core';
import {  FileUploader } from 'ng2-file-upload';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';

const URL = '/api/upload-pipeline';

@Component({
  selector: 'app-upload-pipeline',
  templateUrl: './upload-pipeline.component.html',
  styleUrls: ['./upload-pipeline.component.css']
})
export class UploadPipelineComponent implements OnInit {

  public uploadBtnText: string = 'Upload Data';
  public syncBtnText: string = 'Sync With Elastic Search';

  public uploader: FileUploader = new FileUploader({url: URL, itemAlias: 'data'});
  public hasBaseDropZoneOver: boolean;
  response:string;

  public showSyncEsButton: boolean = false
  public showSyncEsProgress: number = 1
  public showSyncEsProgressBar: boolean = false

  public user: any = {}

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit() {
    if (localStorage.getItem("user") === null) this.router.navigate([''])

    this.user = JSON.parse(localStorage.getItem('user'))

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
        //  console.log('ImageUpload:uploaded:', item, status, response);
        console.log(response)
        let json = JSON.parse(response)
        console.log(typeof json.success, json)
        if(!json.success) {
          alert('Error: '+json.message)
        } else {
          alert('Uploaded Successfully!...')

          let getSyncStatus = setInterval(() => {
            this.userService.getSyncWithESStatus().subscribe(
              response => {
                this.showSyncEsButton = response.status
                this.showSyncEsProgress = response.progress

                if(this.showSyncEsProgress == 100) {
                  clearInterval(getSyncStatus)
                  setTimeout(() => {
                    this.showSyncEsProgressBar = false
                  }, 2000)
                } else {
                  this.showSyncEsProgressBar = true
                }
              }
            )
          }, 10000)
        }
        this.uploadBtnText = 'Upload Data'
     };

    this.userService.getSyncWithESStatus().subscribe(
      response => {
        this.showSyncEsButton = response.status
        this.showSyncEsProgress = response.progress
        if(this.showSyncEsProgress <= 1 || this.showSyncEsProgress == 100) {
          this.showSyncEsProgressBar = false
        }
      }
    )
  }

  fileOverBase(e:any):void {
    this.hasBaseDropZoneOver = e;
  }

  startUploading() {
    this.uploadBtnText = 'Uploading...'
    this.showSyncEsButton = false
    this.showSyncEsProgress = 1
    this.showSyncEsProgressBar = true
  }

  syncWithES() {
    this.syncBtnText = 'Syncing...'
    this.userService.syncWithES().subscribe(
      response => {
        this.syncBtnText = 'Sync With Elastic Search'
        alert('Syncing process has been completed successfully.')
      }
    )
  }

}
