import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { Router } from "@angular/router";
import 'rxjs/add/operator/map';

declare var $: any;

import swal from 'sweetalert2';

@Component({
  selector: 'app-member',
  templateUrl: './member.component.html',
  styleUrls: ['./member.component.css']
})
export class MemberComponent implements OnInit {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<String> = new Subject()

  
  selectedOrganization: any = {}
  subscribers: any = []

  fromDate: String = ''
  tillDate: String = ''

	public memberDetailsModal: string = "Add new member"

  public organizationData: any = {
    "member": [{
      type: 'Manager'
    }],
    "target": [],
    "country": "",
    "organizationType": ""
  }

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  public organizationList: any = []

  public user: any = {}

  public countries: any = []
  public organizationTypes: any = []

  public activeTabs: any = []

  public selectedTab: string = 'organization'

  public caretEventHandled: number = 0

  @ViewChild('submitSetupOrganizationModel') submitSetupOrganizationModel:ElementRef;
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;
  @ViewChild('organizationFormElement') organizationForm:ElementRef;
  @ViewChild('resetMemberModal') resetMemberModal: ElementRef;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) this.router.navigate([''])

    this.user = JSON.parse(localStorage.getItem('user'))

    var today = new Date();


    this.fromDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
    this.tillDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');

    console.log(this.fromDate);

    var popOverSettings = {
      placement: 'right',
      trigger: 'hover',
      html: true,
      selector: '[data-toggle="popover"]'
    }
    
    $('body').popover(popOverSettings);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.userService.getMemberList().subscribe(
      organizationList => {
        this.organizationList = organizationList
        this.dtTrigger.next()

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns().every(function () {
            const that = this;
            $('input', this.header()).on('keyup change', function () {
              if (that.search() !== this['value']) {
                that
                  .search(this['value'])
                  .draw();
              }
            });
          });
        });
      }
    )

    this.userService.getCountryList().subscribe(
      countries => {
        this.countries = countries
        this.countries.unshift({name:'Choose...', code: ''})
      }
    )
    
    this.userService.getOrganizationType().subscribe(
      organizationType => {
        this.organizationTypes = organizationType
        this.organizationTypes.unshift({type:'Choose...', typeId: ''})
      }
    )

    $(document).on('click', '.caret', function() {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    })
  }

  someClickHandler(info: any): void {
    console.log(info)
  }

  ngAfterViewInit(): void {
    // console.log(this.dtElement)
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.columns().every(function () {
    //     const that = this;
    //     $('input', this.footer()).on('keyup change', function () {
    //       if (that.search() !== this['value']) {
    //         that
    //           .search(this['value'])
    //           .draw();
    //       }
    //     });
    //   });
    // });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    console.log(this.organizationData.member)
    this.addActiveTab((this.organizationData.member.length - 1))

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   toggler[toggler.length - 1].addEventListener("click", function() {
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }, 500)
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }

  addNewTarget() {
    this.organizationData.target.push({})
  }

  deleteTarget(targetIndex) {
    this.organizationData.target.splice(targetIndex, 1)
  }

  addNewMemberTarget(memberIndex) {
    if(!this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'] = []
    this.organizationData.member[memberIndex]['target'].push({})
  }

  deleteMemberTarget(memberIndex, targetIndex) {
    console.log(memberIndex, targetIndex, this.organizationData.member)
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)
    console.log(memberIndex, targetIndex, this.organizationData.member)
  }

  loadContent(organization) {

    console.log(organization)
    this.activeTabs = []
    console.log(organization)
		this.memberDetailsModal = 'Update member details'
    this.organizationData = organization
    this.organizationData.member[0].confirmEmail = this.organizationData.member[0].email
    this.selectTab('organization')
    // this.submitSetupOrganizationModel.nativeElement.style.display = 'none'

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   for (var i = this.caretEventHandled; i < toggler.length; i++) {
    //     toggler[i].addEventListener("click", function() {
    //       this.parentElement.querySelector(".nested").classList.toggle("active");
    //       this.classList.toggle("caret-down");
    //     });
    //     this.caretEventHandled = (toggler.length - 1)
    //   }
    // }, 500)
  }

  loadSubscribers(organization) {
    console.log(organization)
    this.selectedOrganization = organization
    this.subscribers = organization.member
  }

  subscribeForNotification() {
    console.log(this.selectedOrganization)
    this.organizationData = this.selectedOrganization
    this.saveMember();
  }

  clearContent() {
		this.memberDetailsModal = 'Add new member'
    this.organizationData = {
      "member": [{
        type: 'Manager'
      }],
      "target": [],
      "name": {},
      "country": "",
      "organizationType": ""
    }
    this.submitSetupOrganizationModel.nativeElement.style.display = 'block'
    this.activeTabs = []
  }

  deleteOrganization(organization, organizationIndex) {
    swal({
      title: 'Are you sure?',
      html: "Do you want to delete <strong>"+organization.organizationName+"</strong>?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        // localStorage.clear()
        // this.router.navigate(['']);
        this.userService.deleteOrganization(organization).subscribe(
          data => {
            if(data.status) {
              this.organizationList.splice(organizationIndex, 1)
              swal({
                title: 'Deleted Successfully',
                type: 'success',
                confirmButtonText: 'Okay'
              })
            } else {
              swal({
                title: 'Something goes wrong!',
                text: 'Please try again',
                type: 'error',
                confirmButtonText: 'Okay'
              })
            }
          })
      }
    })
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        this.router.navigate(['']);
      }
    })
  }

  updateSubscribers() {
    this.subscribers
  }

  saveMember() {
    console.log(this.organizationData)
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          if(!this.organizationData._id) {
            this.organizationData._id = data.id
            this.organizationList.push(this.organizationData)
          }

          this.closeSetupOrganizationModel.nativeElement.click();

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });

          this.organizationData = {
            "member": [{
              type: 'Manager'
            }],
            "target": [],
            "name": {},
            "country": "",
            "organizationType": ""
          }
          this.activeTabs = [];
          this.selectTab('organization');
          // this.organizationForm.nativeElement.reset();
          this.resetMemberModal.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }
}
