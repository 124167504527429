import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';

import swal from 'sweetalert2';
import { Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: any = {}

  invalidLogin: boolean = false;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    if (localStorage.getItem("user") != null) this.router.navigate(['members'])
  }

  signIn() {
    this.login.userType = 'A'
    this.userService.login(this.login).subscribe(
      data => {
        if(data.length > 0) {
          localStorage.setItem('user', JSON.stringify(data[0]))
          this.router.navigate(['members']);
        } else {
          this.invalidLogin = true
        }
      }
    );
  }
}
