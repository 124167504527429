import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { Router } from "@angular/router";
import 'rxjs/add/operator/map';

declare var $: any;

import swal from 'sweetalert2';

@Component({
  selector: 'app-pipeline',
  templateUrl: './pipeline.component.html',
  styleUrls: ['./pipeline.component.css']
})
export class PipelineComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<String> = new Subject()

  
  selectedOrganization: any = {}
  subscribers: any = []

  fromDate: String = ''
  tillDate: String = ''
  
  public showScrapeButton: boolean = true
	public memberDetailsModal: string = "Add new member"

  public organizationData: any = {
    "pipelineWebsite": "",
    "pipelineUrl": "",
    "city": "",
    "state": "",
    "country": "",
    "description": ""
  }

  public selectedOrganizationIndex: any

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  public organizationList: any = []

  public user: any = {}

  public countries: any = []
  public organizationTypes: any = []

  public activeTabs: any = []

  public selectedTab: string = 'organization'

  public caretEventHandled: number = 0

  // Pipeline
  public companyName: string = ''
  public countryFilter: string = ''
  public cityFilter: string = ''
  public stateFilter: string = ''
  public scrapedFilter: string = ''
  public columnSearchVisibility: any = {
    'country': false,
    'companyName': false,
    'city': false,
    'state': false,
    'scraped': false
  }

  //Pagination
  public selectedPage: number = 1
  public recordLength: number = 10
  public pagination: any = []
  public totalPages: number = 1
  public searchKeyword: any = ''
  public totalRecordsInDb: number = 0
  public allIdeasCount: number = 0
  public targetedIdeasCount: number = 0
  public filteredRecords: number = 0

  @ViewChild('submitSetupOrganizationModel') submitSetupOrganizationModel:ElementRef;
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;
  @ViewChild('organizationFormElement') organizationForm:ElementRef;
  @ViewChild('resetMemberModal') resetMemberModal: ElementRef;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) this.router.navigate([''])

    this.user = JSON.parse(localStorage.getItem('user'))

    var today = new Date();


    this.fromDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
    this.tillDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');

    console.log(this.fromDate);

    var popOverSettings = {
      placement: 'right',
      trigger: 'hover',
      html: true,
      selector: '[data-toggle="popover"]'
    }
    
    $('body').popover(popOverSettings);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10
    };

    this.userService.getProductPipeline().subscribe(
      organizationList => {
        this.organizationList = organizationList.hits.hits.map((organization) => {
          return organization._source
        });
        console.log(organizationList)

        if(organizationList.allIdeasCount) {
          this.allIdeasCount = organizationList.allIdeasCount
          if(this.totalRecordsInDb == 0) this.totalRecordsInDb = organizationList.allIdeasCount
        }
        this.targetedIdeasCount = organizationList.recordsTotal
    
        // this.showEmptyResultMsg = (organizationList.hits.hits.length == 0);
        this.filteredRecords = organizationList.recordsTotal
        this.totalPages = Math.ceil(this.filteredRecords / this.recordLength)
    
        if(this.totalPages > 1000) this.totalPages = 1000

        this.loadPagination()
      }
    )

    this.userService.getCountryList().subscribe(
      countries => {
        this.countries = countries
        this.countries.unshift({name:'Choose...', code: ''})
      }
    )
    
    this.userService.getOrganizationType().subscribe(
      organizationType => {
        this.organizationTypes = organizationType
        this.organizationTypes.unshift({type:'Choose...', typeId: ''})
      }
    )

    $(document).on('click', '.caret', function() {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    })
  }

  someClickHandler(info: any): void {
    console.log(info)
  }

  ngAfterViewInit(): void {
    // console.log(this.dtElement)
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.columns().every(function () {
    //     const that = this;
    //     $('input', this.footer()).on('keyup change', function () {
    //       if (that.search() !== this['value']) {
    //         that
    //           .search(this['value'])
    //           .draw();
    //       }
    //     });
    //   });
    // });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
    console.log(this.organizationData.member)
    this.addActiveTab((this.organizationData.member.length - 1))

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   toggler[toggler.length - 1].addEventListener("click", function() {
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //     this.classList.toggle("caret-down");
    //   });
    // }, 500)
  }

  deleteProductPipeline(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
    let activeTabIndex = this.activeTabs.indexOf((memberIndex - 1))
    this.activeTabs.splice(activeTabIndex, 1)
    this.selectTab('organization')
  }

  addNewTarget() {
    this.organizationData.target.push({})
  }

  deleteTarget(targetIndex) {
    this.organizationData.target.splice(targetIndex, 1)
  }

  addNewMemberTarget(memberIndex) {
    if(!this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'] = []
    this.organizationData.member[memberIndex]['target'].push({})
  }

  deleteMemberTarget(memberIndex, targetIndex) {
    console.log(memberIndex, targetIndex, this.organizationData.member)
    if(this.organizationData.member[memberIndex]['target']) this.organizationData.member[memberIndex]['target'].splice(targetIndex, 1)
    console.log(memberIndex, targetIndex, this.organizationData.member)
  }

  loadContent(organization, organizationIndex) {
    this.showScrapeButton = true;
    console.log(organization)
    this.activeTabs = []
    console.log(organization)
		this.memberDetailsModal = 'Update pipeline details'
    this.organizationData = organization
    this.selectedOrganizationIndex = organizationIndex
    // this.selectTab('organization')
    // this.submitSetupOrganizationModel.nativeElement.style.display = 'none'

    // setTimeout(() => {
    //   var toggler = document.getElementsByClassName("caret");

    //   for (var i = this.caretEventHandled; i < toggler.length; i++) {
    //     toggler[i].addEventListener("click", function() {
    //       this.parentElement.querySelector(".nested").classList.toggle("active");
    //       this.classList.toggle("caret-down");
    //     });
    //     this.caretEventHandled = (toggler.length - 1)
    //   }
    // }, 500)
  }

  loadSubscribers(organization) {
    console.log(organization)
    this.selectedOrganization = organization
    this.subscribers = organization.member
  }

  subscribeForNotification() {
    console.log(this.selectedOrganization)
    this.organizationData = this.selectedOrganization
    this.savePipeline();
  }

  clearContent() {
    this.showScrapeButton = false
		this.memberDetailsModal = 'Add new pipeline'
    this.organizationData = {
      "member": [{
        type: 'Manager'
      }],
      "target": [],
      "name": {},
      "country": "",
      "organizationType": ""
    }
    this.submitSetupOrganizationModel.nativeElement.style.display = 'block'
    this.activeTabs = []
  }

  deletePipeline(organization, organizationIndex) {
    swal({
      title: 'Are you sure?',
      html: "Do you want to delete <strong>"+organization.pipelineWebsite+"</strong>?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        // localStorage.clear()
        // this.router.navigate(['']);
        this.userService.deletePipeline(organization).subscribe(
          data => {
            if(data.status) {
              this.organizationList.splice(organizationIndex, 1)
              swal({
                title: 'Deleted Successfully',
                type: 'success',
                confirmButtonText: 'Okay'
              })
            } else {
              swal({
                title: 'Something goes wrong!',
                text: 'Please try again',
                type: 'error',
                confirmButtonText: 'Okay'
              })
            }
          })
      }
    })
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        this.router.navigate(['']);
      }
    })
  }

  updateSubscribers() {
    this.subscribers
  }

  savePipeline() {
    console.log(this.organizationData)
    this.userService.savePipeline(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Product Pipeline Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Product Pipeline is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          if(!this.organizationData._id) {
            this.organizationData._id = data.id
            this.organizationList.push(this.organizationData)
          }

          this.closeSetupOrganizationModel.nativeElement.click();

          // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //   dtInstance.destroy();
          //   this.dtTrigger.next();
          // });

          this.organizationData = {
            "member": [{
              type: 'Manager'
            }],
            "target": [],
            "name": {},
            "country": "",
            "organizationType": ""
          }
          this.activeTabs = [];
          this.selectTab('organization');
          // this.organizationForm.nativeElement.reset();
          this.resetMemberModal.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  addActiveTab(tabId) {
    if(this.activeTabs.indexOf(tabId) == -1) {
      this.activeTabs.push(tabId)
    }
    this.selectedTab = tabId
  }

  closeActiveTab(tabId) {
    this.activeTabs.splice(this.activeTabs.indexOf(tabId), 1)
    this.selectTab('organization')
  }

  selectTab(tabName) {
    this.selectedTab = tabName;
  }

  scrapUrl(organization, organizationIndex) {
    swal({
      title: 'Are you sure?',
      html: "Do you want to scrape <strong>"+organization.pipelineWebsite+"</strong>?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Scrape'
    }).then((result) => {
      if (result.value) {
        // localStorage.clear()
        // this.router.navigate(['']);
        this.userService.scrapUrl(organization._id).subscribe(
          data => {
            if(data.status) {
              this.organizationList[organizationIndex].html = 'Scraped'

              // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              //   dtInstance.destroy();
              //   this.dtTrigger.next();
              // });
              
              swal({
                title: 'Scraped Successfully',
                type: 'success',
                confirmButtonText: 'Okay'
              })
            } else {
              swal({
                title: 'Something goes wrong!',
                text: 'Please try again',
                type: 'error',
                confirmButtonText: 'Okay'
              })
            }
          })
      }
    })
  }

  scrapAllWesbites() {
    swal({
      title: 'Are you sure?',
      html: "Do you want to scrape all websites?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Scrape'
    }).then((result) => {
      if (result.value) {
        // localStorage.clear()
        // this.router.navigate(['']);
        this.userService.scrapAllWebsites().subscribe((response) => {
          console.log(response)
        })

        swal({
          title: 'Scraped Successfully',
          type: 'success',
          confirmButtonText: 'Okay'
        })
      }
    })
  }

  showColumnClear(columnName) {
    this.columnSearchVisibility[columnName] = true
  }

  clearColumnSearch(columnName) {
    switch (columnName) {
      case 'companyName':
        this.companyName = '';
        break;
      case 'city':
        this.cityFilter = '';
        break;
      case 'state':
        this.stateFilter = '';
        break;
      case 'country':
        this.countryFilter = '';
        break;
      case 'scraped':
        this.scrapedFilter = '';
        break;
    }
    this.columnSearchVisibility[columnName] = false
  }

  filterPipelineResult(gotoFirstPage) {
    let filters = {}
    
    if(gotoFirstPage) {
      filters['start'] = 0
    } else {
      filters['start'] = ((this.selectedPage - 1) * this.recordLength)
    }
    filters['length'] = this.recordLength

    filters['getRefinedByCount'] = true

    if(this.searchKeyword != '') {
      filters['search'] = this.searchKeyword
    }

    filters['country'] = this.countryFilter
    filters['city'] = this.cityFilter
    filters['companyName'] = this.companyName
    filters['state'] = this.stateFilter
    filters['isScraped'] = this.scrapedFilter


    // filters['isBackToSearch'] = this.isBackToSearch;

    
    this.userService.getProductPipeline(filters).subscribe(
      abstracts => {
        // this.isTargetUpdated = false;
        // if(abstracts.success || abstracts.success == false) this.sessionTimeOut()
        
        // this.loadingSearchResult = false
        
        this.updateIdeasListForPipeline(abstracts)

        this.loadPagination()

      }
    )
  }

  updateIdeasListForPipeline(abstracts) {
    console.log(abstracts)
    this.organizationList = []
    // this.ideaTypesKeys.forEach((key, i) => this.ideaTypes[key] = 0)

    if(abstracts.allIdeasCount) {
      this.allIdeasCount = abstracts.allIdeasCount
      if(this.totalRecordsInDb == 0) this.totalRecordsInDb = abstracts.allIdeasCount
    }
    this.targetedIdeasCount = abstracts.recordsTotal

    // this.showEmptyResultMsg = (abstracts.hits.hits.length == 0);
    this.filteredRecords = abstracts.recordsTotal
    this.totalPages = Math.ceil(this.filteredRecords / this.recordLength)

    if(this.totalPages > 1000) this.totalPages = 1000

    abstracts.hits.hits.forEach(abstract => {
      let abstractData = Object.assign({}, abstract._source)
      abstractData['_id'] = abstract._id
      this.organizationList.push(abstractData)

      // this.ideaTypesKeys.forEach(key => {
      //   this.ideaTypes[key] += (abstract[this.ideaTypesColumnMapping[key]] == 'Y') ? 1 : 0
      // });
    })

    if(document.querySelector('.popover')) {
      document.querySelector('.popover').remove()
    }
  }

  loadPagination() {
    this.pagination = []
    
    if(this.totalPages <= 6) {
      for(let pageNo = 1; pageNo <= this.totalPages; pageNo++) {
        this.pagination.push({
          pageNo: pageNo,
          active: (pageNo == this.selectedPage)
        })
      }
    } else {
      if(this.selectedPage > 3)
      this.pagination.push({
        pageNo: 1,
        active: false
      })
      if(this.selectedPage > 4)
        this.pagination.push({
          pageNo: '...',
          active: false
        })

      let renderPageNo = (this.selectedPage - 2)
      for(let renderedCount = 0; renderedCount < 5; renderedCount++) {
        if(renderPageNo >= 1 && renderPageNo <= this.totalPages) {
          this.pagination.push({
            pageNo: renderPageNo,
            active: (renderPageNo == this.selectedPage)
          })
        }
        renderPageNo++
      }
      
      if(this.selectedPage <= (this.totalPages - 4))
        this.pagination.push({
          pageNo: '...',
          active: false
        })
      if(this.selectedPage <= (this.totalPages - 3))
        this.pagination.push({
          pageNo: this.totalPages,
          active: false
        })
    }
  }

  navigateToPage(selectedPage) {
    if(selectedPage == '...') return
    // this.loadingSearchResult = true;
    this.selectedPage = selectedPage
    this.filterPipelineResult(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  navigateToNexPage() {
    // this.loadingSearchResult = true;
    this.selectedPage = (this.selectedPage != this.totalPages) ? this.selectedPage + 1 : this.selectedPage
    this.filterPipelineResult(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  navigateToPreviousPage() {
    // this.loadingSearchResult = true;
    this.selectedPage = (this.selectedPage != 1) ? this.selectedPage - 1 : this.selectedPage
    this.filterPipelineResult(false)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  clearCustomSearch() {
    this.searchKeyword = ''
    this.filterPipelineResult(true)
  }
}
