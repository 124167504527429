import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpModule } from '@angular/http';
import { FileUploadModule } from 'ng2-file-upload';

import { SharedModule } from './shared/shared.module';
import { DataTablesModule } from 'angular-datatables';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MemberComponent } from './member/member.component';
import { IdeasComponent } from './ideas/ideas.component';
import { UploadDataComponent } from './upload-data/upload-data.component';
import { AlertComponent } from './alert/alert.component';
import { PipelineComponent } from './pipeline/pipeline.component';
import { UploadPipelineComponent } from './upload-pipeline/upload-pipeline.component';
import { PipelineActionsComponent } from './pipeline-actions/pipeline-actions.component';

// Define the routes
const routes:Routes = [
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'members',
    component: MemberComponent
  },
  {
    path: 'ideas',
    component: IdeasComponent
  },
  {
    path: 'upload-data',
    component: UploadDataComponent
  },
  {
    path: 'manage-alerts',
    component: AlertComponent
  },
  {
    path: 'pipelines',
    component: PipelineComponent
  },
  {
    path: 'upload-pipeline',
    component: UploadPipelineComponent
  }
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MemberComponent,
    IdeasComponent,
    UploadDataComponent,
    AlertComponent,
    PipelineComponent,
    UploadPipelineComponent,
    PipelineActionsComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    SharedModule,
    HttpModule,
    FormsModule,
    DataTablesModule,
    FileUploadModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
