import { Component, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { PipelineActionComponentEventType } from './pipeline-action-event-type';

@Component({
  selector: 'app-pipeline-actions',
  templateUrl: './pipeline-actions.component.html',
  styleUrls: ['./pipeline-actions.component.css']
})
export class PipelineActionsComponent implements OnInit {

  constructor() { }

  @Output()
  emitter = new Subject<PipelineActionComponentEventType>();

  @Input()
  data = {};

  ngOnInit(): void {console.log("asdf")}

  onAction1() {
    this.emitter.next({
      cmd: "action1",
      data: this.data,
    });
  }

  ngOnDestroy() {
    this.emitter.unsubscribe();
  }
}
