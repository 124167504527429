import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { Router } from "@angular/router";
import 'rxjs/add/operator/map';

import swal from 'sweetalert2';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {}
  dtTrigger: Subject<String> = new Subject()

  
  selectedOrganization: any = {}
  subscriber: any = {}

  missedNotification: any = []

  fromDate: String = ''
  tillDate: String = ''

  public memberDetailsModal: string = "Add new member"
  
  public sendNotificationText: String = 'Send Notification'

  public organizationData: any = {
    "member": [{
      type: 'Manager'
    }],
    "target": [],
    "country": "",
    "organizationType": ""
  }

  public memberTypeList = [
    "Subordinate",
    "Restricted Member"
  ]

  public organizationList: any = []

  public user: any = {}

  public countries: any = []
  public organizationTypes: any = []

  public hasMissedNotification: boolean = false
  public notificationSent: boolean = false

  @ViewChild('submitSetupOrganizationModel') submitSetupOrganizationModel:ElementRef;
  @ViewChild('closeSetupOrganizationModel') closeSetupOrganizationModel:ElementRef;
  @ViewChild('organizationFormElement') organizationForm:ElementRef;
  @ViewChild('subscriptionCloseModal') subscriptionCloseModal: ElementRef;
  @ViewChild('sendNotificationCloseModal') sendNotificationCloseModal: ElementRef;

  constructor(private userService: UserService, private router: Router) {
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) this.router.navigate([''])

    this.user = JSON.parse(localStorage.getItem('user'))

    var today = new Date();


    this.fromDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');
    this.tillDate = today.getFullYear()+'-'+(today.getMonth()+1).toString().padStart(2, '0')+'-'+today.getDate().toString().padStart(2, '0');

    console.log(this.fromDate);

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      dom: 'lrtip',
      order: [[1, "asc"]]
    };

    this.userService.getMembersForAlerts().subscribe(
      organizationList => {
        this.organizationList = organizationList
        this.dtTrigger.next()

        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.columns().every(function () {
            const that = this;
            $('input , select', this.header()).on('keyup change', function () {
              console.log(this['value'])
              if (that.search() !== this['value']) {
                that
                  .search(this['value'])
                  .draw();
              }
            });
          });
        });
      }
    )

    this.userService.getCountryList().subscribe(
      countries => {
        this.countries = countries
        this.countries.unshift({name:'Choose...', code: ''})
      }
    )
    
    this.userService.getOrganizationType().subscribe(
      organizationType => {
        this.organizationTypes = organizationType
        this.organizationTypes.unshift({type:'Choose...', typeId: ''})
      }
    )
  }

  someClickHandler(info: any): void {
    console.log(info)
  }

  ngAfterViewInit(): void {
    // console.log(this.dtElement)
    // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
    //   dtInstance.columns().every(function () {
    //     const that = this;
    //     $('input', this.footer()).on('keyup change', function () {
    //       if (that.search() !== this['value']) {
    //         that
    //           .search(this['value'])
    //           .draw();
    //       }
    //     });
    //   });
    // });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addNewMember() {
    this.organizationData.member.push({
      type: 'Subordinate'
    })
  }

  deleteMember(memberIndex) {
    this.organizationData.member.splice(memberIndex, 1)
  }

  addNewTarget() {
    this.organizationData.target.push({})
  }

  deleteTarget(targetIndex) {
    this.organizationData.target.splice(targetIndex, 1)
  }

  loadContent(organization) {
		this.memberDetailsModal = 'Update member details'
    this.organizationData = organization
    this.organizationData.confirmEmail = this.organizationData.email
    // this.submitSetupOrganizationModel.nativeElement.style.display = 'none'
  }

  loadSubscribers(organization, organizationIndex) {
    this.selectedOrganization = organization
    this.subscriber = organization.member
    this.organizationList[organizationIndex].member.subscribed = !this.organizationList[organizationIndex].member.subscribed
    this.updateSubscription()
  }

  subscribeForNotification() {
    console.log(this.selectedOrganization)
    this.organizationData = this.selectedOrganization
    // this.saveMember();
  }

  clearContent() {
		this.memberDetailsModal = 'Add new member'
    this.organizationData = {
      "member": [{
        type: 'Manager'
      }],
      "target": [],
      "name": {},
      "country": "",
      "organizationType": ""
    }
    this.submitSetupOrganizationModel.nativeElement.style.display = 'block'
  }

  deleteOrganization(organization, organizationIndex) {
    swal({
      title: 'Are you sure?',
      html: "Do you want to delete <strong>"+organization.organizationName+"</strong>?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Delete'
    }).then((result) => {
      if (result.value) {
        // localStorage.clear()
        // this.router.navigate(['']);
        this.userService.deleteOrganization(organization).subscribe(
          data => {
            if(data.status) {
              this.organizationList.splice(organizationIndex, 1)
              swal({
                title: 'Deleted Successfully',
                type: 'success',
                confirmButtonText: 'Okay'
              })
            } else {
              swal({
                title: 'Something goes wrong!',
                text: 'Please try again',
                type: 'error',
                confirmButtonText: 'Okay'
              })
            }
          })
      }
    })
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        this.router.navigate(['']);
      }
    })
  }

  updateSubscription() {
    let newStatus = this.subscriber.subscribed
    this.userService.updateSubscription(this.selectedOrganization._id, this.subscriber._id, newStatus).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Subscription Status has been updated successfully!'
          // swal({
          //   title: alertMessage,
          //   type: 'success',
          //   confirmButtonText: 'Okay'
          // })

          this.subscriptionCloseModal.nativeElement.click();

          // this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          //   dtInstance.destroy();
          //   this.dtTrigger.next();
          // });

        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  saveMember() {
    this.userService.saveMember(this.organizationData).subscribe(
      data => {
        if(data.status) {
          let alertMessage = 'Member Details has been updated successfully!'
          if(!this.organizationData._id) alertMessage = 'New Member is added successfully!'
          swal({
            title: alertMessage,
            type: 'success',
            confirmButtonText: 'Okay'
          })

          if(!this.organizationData._id) {
            this.organizationData._id = data.id
            this.organizationList.push(this.organizationData)
          }

          this.closeSetupOrganizationModel.nativeElement.click();

          this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.destroy();
            this.dtTrigger.next();
          });

          this.organizationData = {
            "member": [{
              type: 'Manager'
            }],
            "target": [],
            "name": {},
            "country": "",
            "organizationType": ""
          }
          this.organizationForm.nativeElement.reset();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }

  sendNotification() {
    this.sendNotificationText = 'Sending'
    var filteredOrg = this.organizationList.filter(organization => organization.member.subscribed)
    this.userService.sendNotification(filteredOrg, this.fromDate, this.tillDate).subscribe((response) => {
      if(response.missedNoticiation.length > 0) {
        this.missedNotification = response.missedNoticiation
        this.hasMissedNotification = true
      }
    })

    setTimeout(() => {
      this.notificationSent = true
      this.sendNotificationText = 'Send Notification'
      this.sendNotificationCloseModal.nativeElement.click();
    }, 2000)
  }
}
