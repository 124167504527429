import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/map';

@Injectable()
export class UserService {
    constructor(private http: Http) {}

    getCountryList() {
        var url = "/api/country-list"

        return this.http.get(url)
            .map(res => res.json())
    }

    getOrganizationType() {
        var url = "/api/organization-type"

        return this.http.get(url)
            .map(res => res.json())
    }

    login(user) {
        var url = "/api/login"

        return this.http.post(url, user)
            .map(res => res.json())
    }

    getGRIDList() {
        var url = "/api/grid-list"

        return this.http.get(url)
            .map(res => res.json())
    }

    deleteOrganization(organization) {
        var url ="/api/delete-member?id="+organization._id
        return this.http.delete(url)
            .map(res => res.json())
    }

    deletePipeline(organization) {
        var url ="/api/delete-pipeline?id="+organization._id
        return this.http.delete(url)
            .map(res => res.json())
    }

    saveMember(member) {
        var url = "/api/save-member"

        return this.http.post(url, member)
            .map(res => res.json())
    }

    savePipeline(pipeline) {
        var url = "/api/save-pipeline"

        return this.http.post(url, pipeline)
            .map(res => res.json())
    }

    getMemberList() {
        var url = "/api/member-list"

        return this.http.get(url)
            .map(res => res.json())
    }

    getProductPipeline(filters = null) {
        var url = "/api/pipelines?"

        for (let filter in filters) {
            if(filters[filter] instanceof Array) {
                filters[filter].forEach(keyword => {
                    url += '&'+filter+'='+keyword
                });
            } else {
                url += '&'+filter+'='+filters[filter]
            }
        }

        let token = localStorage.getItem('token')
        let headers = new Headers({"x-access-token": token});
        let options = new RequestOptions({ headers: headers });

        return this.http.get(url, options)
            .map(res => res.json())
    }

    getMembersForAlerts() {
        var url = "/api/member-for-alerts"

        return this.http.get(url)
            .map(res => res.json())
    }

    getOrganizationTypes() {
        var url = "/api/organization-type"

        return this.http.get(url)
            .map(res => res.json())
    }

    getIdeas(id, filters = null) {
        var url = "/api/list-ideas?id="+id

        for (let filter in filters) {
            filters[filter].forEach(keyword => {
                url += '&'+filter+'='+keyword
            });
        }

        return this.http.get(url)
            .map(res => res.json())
    }

    saveAbstract(abstract) {
        var url = "/api/save-abstract"

        return this.http.post(url, abstract)
            .map(res => res.json())
    }

    updateSubscription(organizationId, memberId, subscriptionStatus) {
        var url = "/api/update-subscription-status"

        var data = {
            "organizationId": organizationId,
            "memberId": memberId, 
            "status" : subscriptionStatus
        }

        return this.http.post(url, data)
            .map(res => res.json())
    }

    syncWithES() {
        var url = "/api/sync-with-es"

        return this.http.get(url)
            .map(res => res.json())
    }

    getSyncWithESStatus() {
        var url = "/api/get-sync-es-status"

        return this.http.get(url)
            .map(res => res.json())
    }

    sendNotification(filteredOrg, fromDate, tillDate) {
        var url = "/api/send-notification-new?fromDate="+fromDate+"&tillDate="+tillDate

        filteredOrg.forEach(organization => {
            url += "&members[]="+organization.member._id
        });

        return this.http.get(url)
            .map(res => res.json())
    }

    scrapUrl(organizationId) {
        var url = "/api/scrape-pipeline-urls?id="+organizationId

        return this.http.get(url)
            .map(res => res.json())
    }

    scrapAllWebsites() {
        var url = "/api/scrape-pipeline-urls"

        console.log(url)
        return this.http.get(url)
            .map(res => res.json())
    }
}