import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../shared/user.service';

import {DataTableModule} from "angular-6-datatable";

import { Router, ActivatedRoute, Params } from "@angular/router";

import swal from 'sweetalert2';

@Component({
  selector: 'app-ideas',
  templateUrl: './ideas.component.html',
  styleUrls: ['./ideas.component.css']
})
export class IdeasComponent implements OnInit {

  public targetTitle: any = []
  public abstractIndex: number;
  public abstractData: any = {
    "ideaSubmitted": {},
    "ideaFunded": {}
  }
  public types: any = []
  public abstracts: any = []
  public analytics: any = []
  public organizationTypes: any = []
  public ideaTypes: any = ['New Ideas', 'Submitted', 'Funded', 'Tech Transfer']

  public appliedFilters: any = {
    'ideaTypes': [],
    'organizationTypes': []
  }

  public user: any = {}

  @ViewChild('submitAbstractModel') submitAbstractModel:ElementRef;
  @ViewChild('closeAbstractModel') closeAbstractModel:ElementRef;
  @ViewChild('abstractFormElement') abstractForm:ElementRef;

  constructor(private userService: UserService, private renderer: Renderer2, private router: Router, private activatedRouter: ActivatedRoute) {
    this.renderer.addClass(document.body, 'bg-light');
    this.renderer.removeClass(document.body, 'bg-blue');
  }

  ngOnInit() {
    if (localStorage.getItem("user") === null) this.router.navigate([''])

    this.user = JSON.parse(localStorage.getItem('user'))

    this.userService.getOrganizationTypes().subscribe(
      organizationTypes => {
        this.organizationTypes = organizationTypes

        this.userService.getIdeas(this.user._id).subscribe(
          abstracts => {
            this.updateIdeasList(abstracts)
          }
        )
      }
    )

  }

  loadContent(abstract, abstractIndex) {
    this.abstractData = abstract
    this.abstractIndex = abstractIndex
  }

  logout() {
    swal({
      title: 'Are you sure?',
      text: "Do you want to logout?",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'I\'m Sure'
    }).then((result) => {
      if (result.value) {
        localStorage.clear()
        this.router.navigate(['']);
      }
    })
  }

  applyFilters(filterType, keyword, index) {
    if(this.appliedFilters[filterType].indexOf(keyword) != -1)
      this.appliedFilters[filterType].splice(this.appliedFilters[filterType].indexOf(keyword), 1) 
    else
      this.appliedFilters[filterType].push(keyword)

    this.userService.getIdeas(this.user._id, this.appliedFilters).subscribe(
      abstracts => {
        this.updateIdeasList(abstracts)
      }
    )

  }

  updateIdeasList(abstracts) {
    this.abstracts = []
    abstracts.forEach(abstract => {
      this.abstracts.push({
        _id: abstract._id,
        title: abstract.title,
        name: abstract.researcher[0].name.first + ' ' + abstract.researcher[0].name.last,
        organization: abstract.researcher[0].organization,
        country: abstract.researcher[0].address.country,
        type: abstract.organizationType[0].type,
        submittedOn: abstract.submittedOn,
        abstract: abstract.abstract,
        aims: abstract.aims,
        remarks: abstract.remarks,
        data: abstract
      })
    });
  }

  formateDate(date) {
    let dateObj = new Date(date)
    return dateObj.getFullYear()+'-'+("00"+(dateObj.getMonth()+1)).substr(-2)+"-"+("00"+(dateObj.getDate())).substr(-2)
  }

  saveAbstract() {
    console.log(this.abstractData)

    this.userService.saveAbstract(this.abstractData).subscribe(
      data => {
        if(data.status) {
          swal({
            title: 'Idea Details has been updated successfully!',
            type: 'success',
            confirmButtonText: 'Okay'
          })

          this.abstracts[this.abstractIndex] = {
            _id: this.abstractData._id,
            title: this.abstractData.title,
            name: this.abstractData.researcher[0].name.first + ' ' + this.abstractData.researcher[0].name.last,
            organization: this.abstractData.researcher[0].organization,
            country: this.abstractData.researcher[0].address.country,
            type: this.abstractData.organizationType[0].type,
            submittedOn: this.abstractData.submittedOn,
            abstract: this.abstractData.abstract,
            aims: this.abstractData.aims,
            remarks: this.abstractData.remarks,
            data: this.abstractData
          }
          

          this.closeAbstractModel.nativeElement.click();
        } else {
          swal({
            title: 'Something goes wrong!',
            text: 'Please try again',
            type: 'error',
            confirmButtonText: 'Okay'
          })
        }
      }
    );
  }
}
